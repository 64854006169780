.manual-estimate-dialog {
  width: 100%;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 12;
  padding: 0.4rem 5vw;
  box-sizing: border-box;
  overflow-y: scroll;
}

.manual-estimate-dialog .text-description {
  font-size: 0.35rem;
  color: #000000;
}

.manual-estimate-dialog .radio-wrapper {
  margin-top: 0.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.manual-estimate-dialog .radio-wrapper .radio-item {
  font-size: 0.35rem;
  width: 26vw;
  padding: 0.2rem 0;
  text-align: center;
  border: 0.026667rem solid #dcdfe6;
}

.manual-estimate-dialog .radio-wrapper .active {
  background-color: #14b1ff;
  color: #ffffff;
}

.manual-estimate-dialog .rate-wrapper {
  margin-top: 0.2rem;
}

.manual-estimate-dialog .van-field {
  margin-top: 0.2rem;
  background-color: #fafafa;
}

.manual-estimate-dialog .btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.manual-estimate-dialog .btn-wrapper .confirm-btn {
  font-size: 0.35rem;
  padding: 0.2rem 0.5rem;
  background-color: #14b1ff;
  color: #ffffff;
  border-radius: 0.133333rem;
}

.manual-estimate-dialog .top-row {
  display: flex;
}

.manual-estimate-dialog .top-row .icon-wrapper {
  width: 0.533333rem;
  height: 0.533333rem;
  border-radius: 50%;
  box-shadow: 0 0 0.026667rem #666666;
  line-height: 0.613333rem;
  text-align: center;
}

.manual-estimate-dialog .top-row .icon-wrapper i {
  font-size: 0.3rem;
}

.manual-estimate-dialog .top-row .text {
  margin-left: 0.2rem;
  font-weight: 600;
  font-size: 0.4rem;
}

.manual-estimate-dialog .estimate-row {
  box-sizing: border-box;
  width: 100%;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manual-estimate-dialog .estimate-row .submit-btn {
  cursor: pointer;
  padding: 0.2rem;
  width: 2rem;
  color: #ffffff;
  background-color: #00aaff;
  text-align: center;
  font-size: 0.4rem;
}

.manual-estimate-dialog .desc {
  padding: 0.2rem 0;
  text-align: left;
}

.manual-estimate-dialog .textarea-wrapper {
  width: 100%;
  box-sizing: border-box;
}

.manual-estimate-dialog .textarea-wrapper /deep/ textarea {
  margin-top: 0.2rem;
  border: 0.026667rem solid #c8c9cc;
  border-radius: 0.106667rem;
  padding: 0.266667rem;
  transform: translateX(0.8);
  transform: translateX(10rpx);
  box-sizing: border-box;
}

.manual-estimate-dialog .suggerlog-wrapper {
  margin-top: 0.2rem;
  border-radius: 0.133333rem;
  padding: 0.32rem 0.426667rem;
}

.manual-estimate-dialog .suggerlog-wrapper .suggerlog {
  padding: 0.2rem 0;
}

.manual-estimate-dialog .suggerlog-wrapper .suggerlog .line1 {
  display: flex;
  justify-content: space-between;
}

.manual-estimate-dialog .suggerlog-wrapper .suggerlog .line1 .left {
  display: flex;
  align-items: center;
}

.manual-estimate-dialog .suggerlog-wrapper .suggerlog .line1 .left .name {
  max-width: 1.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

.manual-estimate-dialog .suggerlog-wrapper .suggerlog .line1 .left .dafen {
  margin-left: 0.1rem;
  font-weight: 600;
}

.manual-estimate-dialog .suggerlog-wrapper .suggerlog .line2 {
  margin-top: 0.2rem;
}

.manual-estimate-dialog .suggerlog-wrapper .suggerlog .line2 .pingjia {
  font-weight: 600;
}

.manual-estimate-dialog .estimate-log-wrapper {
  margin-top: 1rem;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item {
  margin-top: 0.5rem;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .top-title-row {
  display: flex;
  justify-content: space-between;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .top-title-row .name {
  font-size: 0.35rem;
  color: #000000;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .top-title-row .date {
  font-size: 0.35rem;
  color: #666666;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper {
  margin-top: 0.3rem;
  background-color: #fafafa;
  padding: 0.3rem;
  font-size: 0.35rem;
  color: #666666;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .content-desc {
  font-size: 0.35rem;
  color: #000000;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .result-row {
  margin-top: 0.3rem;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .result-row .text {
  margin-left: 0.3rem;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .rate-row {
  margin-top: 0.3rem;
  display: flex;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .rate-row .van-rate {
  margin-left: 0.3rem;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .estimate-content-row {
  margin-top: 0.3rem;
}

.manual-estimate-dialog .estimate-log-wrapper .estimate-log-item .estimate-content-log .content-wrapper .estimate-content-row .text {
  margin-left: 0.3rem;
}

.manual-estimate-dialog .dialog-wrapper {
  width: 80%;
  margin: 0 auto;
  padding: 1rem 0;
}